import React from "react";
import Styled from "styled-components";
import Colors from "constants/colors";

const LoaderStyle = Styled.div`
    border: 16px solid ${Colors.secondary}
    border-top: 16px solid ${Colors.primary}
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const Loader = () => {
  return <LoaderStyle></LoaderStyle>;
};

export default Loader;
