import React from "react";
import Styled from "styled-components";

const RaceIconStyle = Styled.div`
    height: 80px;
    width: 80px;
    background-image: url(${({ image }) => image});
    background-size: contain;
    background-repeat: no-repeat;
    display:inline-block;
    position: relative;
    z-index: ${({ z_index }) => z_index}


    transform: translateY(${({ y_transformation }) => y_transformation}%);
    -ms-transform: translateY(${({ y_transformation }) => y_transformation}%);
    -moz-transform: translateY(${({ y_transformation }) => y_transformation}%);
    -webkit-transform: translateY(${({ y_transformation }) =>
      y_transformation}%);
    -o-transform: translateY(${({ y_transformation }) => y_transformation}%);
`;

const RaceIcon = ({ image, width, z_index, y_transformation }) => {
  return (
    <RaceIconStyle
      image={image}
      width={width}
      z_index={z_index}
      y_transformation={y_transformation}
    ></RaceIconStyle>
  );
};
export default RaceIcon;
