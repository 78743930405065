import React from 'react';
import styled from "styled-components";

const ContainerStyle = styled.div`
    width: 98vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 10vh;
    justify-content: space-between;
`;

const NavContainer = ({children}) => {
    return (
        <ContainerStyle>
            {children}
        </ContainerStyle>
    );
};

export default NavContainer;