import React from "react";
import Styled from "styled-components";

const StickyTopStyle = Styled.div`
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    z-index: 99 !important;
    width: ${({ width }) => (width ? width : "auto")};
    margin: 10px;
`;

const StickyTop = ({ children, width }) => {
  return <StickyTopStyle width={width}>{children}</StickyTopStyle>;
};

export default StickyTop;
