import { w3cwebsocket as W3CWebSocket } from "websocket";
import Config from "constants/config";
import { Observable } from "rxjs";

let socket;

export const getConnection = (gameId, token) => {
  var url = `${Config.serverUrl}/game/${gameId}?token=${token}`
    .replace("https:", "wss:")
    .replace("http:", "ws:");
  socket = new W3CWebSocket(url);

  console.log(socket);

  const observable = new Observable((subscriber) => {
    socket.onmessage = (event) => {
      subscriber.next(event.data);
    };
  });

  socket.onerror = (event) => {
    console.log(event);
  };

  setInterval(() => {
    let updateMessage = {
      UserID: 0,
      GoalID: 0,
      GameUUID: "",
      Progress: 0,
    };

    socket.send(JSON.stringify(updateMessage));
  }, 40000);

  return observable;
};

export const updateGoalWS = (gameUUID, userID, goalID, progress) => {
  let updateMessage = {
    UserID: userID,
    GoalID: goalID,
    GameUUID: gameUUID,
    Progress: progress,
  };

  socket.send(JSON.stringify(updateMessage));
};
