import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import HContainer from 'components/layouts/HContainer';
import Button from 'components/Button';

const NavBar = ({location}) => {
    const split = location.pathname.split('/');
    const gameUUID = split[split.length-1];

    if(gameUUID === '') return null;

    return (
        <HContainer>
            {location.pathname.includes('gamehistory') ?
                <Link to={`/game/${gameUUID}`} >
                    <Button text="Game" />
                </Link>
                :
                <Link to={`/gamehistory/${gameUUID}`} >
                    <Button text="History" />
                </Link>
                }
        </HContainer>
    );  
};

export default withRouter(NavBar);
