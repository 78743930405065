import React, { useState } from "react";
import Styled, { keyframes } from "styled-components";

import Colors from "constants/colors";
import VContainer from "components/layouts/VContainer";
import HContainer from "components/layouts/HContainer";
import ProgressBox from "./ProgressBox";
import Fade from "../Fade";

import { zoomIn } from "react-animations";
import { getNumOfWorkHours } from "utils/game.utils.js";
import nextRewardImage from "assets/closed_treasure_chest.png";

const fadeInAnimation = keyframes`${zoomIn}`;

const ProgressBarStyle = Styled.div`
    width: ${({ progress }) => progress}%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    text-align: center;
    border-radius: 5px;
    `;

const ProgressContainer = Styled.div`
    width: 100%;
    background-color: ${Colors.placeholderText};
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    animation: 1s ${fadeInAnimation};
    position: relative;
`;

const ProgressBarCurrentDayStyle = Styled.div`
    position: absolute;
    left: ${({ dayProgress }) => dayProgress};
    height: 50px;
    background-color: ${({ dayIndicatorColor }) => dayIndicatorColor};
    top: 50%; 
    display:inline-block;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: ${({ width }) => width}px;

    opacity: 0.8;
    cursor: pointer;
`;
const ProgressBarNextReward = Styled.div` 
    position: absolute;
    height: 50px;
    left: ${({ nxtr }) => nxtr};   
    background-image: url(${({ image }) => image});
    background-size: contain;
    top: 50%; 
    display:inline-block;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: ${({ width }) => width}px;
    opacity: 0.8;
    cursor: pointer;
`;

const ProgressBar = ({
  user,
  startDate,
  endDate,
  goalUpdate,
  primaryColor,
  secondaryColor,
  clickable
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = e => {
    setIsExpanded(!isExpanded);
  };

  var compontWidth = window.innerWidth * 0.8;
  var dayIndicatorWidth = 6;

  // Dates used for setting day indicator on progress bar
  var today = new Date();
  var _startDate = new Date(startDate);
  var _endDate = new Date(endDate);

  var daysInAll = getNumOfWorkHours(_startDate, _endDate);
  var daysPassed = getNumOfWorkHours(_startDate, today);
  var daysPassedScaled =
    compontWidth * Math.min(daysPassed / daysInAll, 1) -
    dayIndicatorWidth / 2 +
    "px"; // Ensuring scaling never exceeds 100%
  console.log(
    "Days passed: " +
      daysPassed +
      " out of " +
      Math.round(daysInAll) +
      " (week days), scaled to: " +
      daysPassedScaled
  );

  //Dea: Reward Logic: if current_progress = reward_progress - show open treasure chest, if current progress< ceil(total_clicks/total_time), show upcoming reward, closed treasure chest
  //calculate total clicks : user.Goals
  var totalclicks = user.Goals.reduce((prev, next) => prev + next.Target, 0); //sum of all the goals target value for the user
  //next reward location = (ceiling value of (sum of all current goal status of the user/clicksperReward))*clicks per reward
  var currentclicks = user.Goals.reduce((prev, next) => prev + next.Current, 0); //sum of all the goals current value for the user
  var nextRewardLocation = user.RewardGiveAt;
  console.log(" Reward Location " + nextRewardLocation);
  var nextRewardLocationScaled =
    compontWidth * Math.min(nextRewardLocation / totalclicks, 1) -
    2 * dayIndicatorWidth +
    "px"; // <-- this should match the width of the treasure chest icon
  console.log(
    user.Name +
      " Current nr of goals " +
      currentclicks +
      " next Reward at " +
      nextRewardLocation +
      " scaled to: " +
      nextRewardLocationScaled
  );

  return (
    <VContainer width={compontWidth + "px"} textAlign="left">
      <h4>{user.Name}:</h4>
      <ProgressContainer onClick={handleClick}>
        <ProgressBarStyle
          progress={user.Progress}
          backgroundColor={primaryColor}
        >
          <ProgressBarCurrentDayStyle
            dayIndicatorColor={secondaryColor}
            width={dayIndicatorWidth}
            dayProgress={
              daysPassed > 0
                ? daysPassedScaled
                : (-1 * dayIndicatorWidth) / 2 + "px"
            }
          ></ProgressBarCurrentDayStyle>
          <ProgressBarNextReward
            width={dayIndicatorWidth * 8}
            image={nextRewardImage}
            nxtr={
              nextRewardLocation >= 0
                ? nextRewardLocationScaled
                : -2 * dayIndicatorWidth + "px"
            }
          ></ProgressBarNextReward>
          {Math.round(user.Progress)}%
        </ProgressBarStyle>
      </ProgressContainer>
      <Fade show={isExpanded}>
        {user ? (
          <HContainer
            height={window.innerHeight * 0.35 + "px"}
            alignItems="stretch"
          >
            {user.Goals.map((goal, i) => {
              const progress = Math.round((goal.Current / goal.Target) * 100);
              console.log(
                user.Name +
                  " User progress: " +
                  progress +
                  " " +
                  goal.Current +
                  " " +
                  goal.Target
              );
              return (
                <ProgressBox
                  key={i}
                  goalIncUpdate={() => goalUpdate(goal, 1)}
                  goalDecUpdate={() => goalUpdate(goal, -1)}
                  name={goal.Name}
                  progress={progress}
                  Current={goal.Current}
                  Target={goal.Target}
                  backgroundColor={secondaryColor}
                  fillColor={primaryColor}
                  clickable={clickable}
                />
              );
            })}
          </HContainer>
        ) : null}
      </Fade>
    </VContainer>
  );
};

export default ProgressBar;
