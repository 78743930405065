import React from "react";
import Styled from "styled-components";

const RepeatBackgroundStyle = Styled.div`
    height: 50%;
    width: 100%;
    
    z-index: 2;
    background:
    url(${({ backgroundUrl }) => backgroundUrl}) repeat-x 0,
    url(${({ backgroundUrl }) => backgroundUrl}) repeat-x 75px;

    background-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : "transparent"};
    margin-bottom: -13px;
`;

const RepeatBackground = ({ backgroundUrl, backgroundColor, children }) => {
  return (
    <RepeatBackgroundStyle
      backgroundUrl={backgroundUrl}
      backgroundColor={backgroundColor}
    >
      {children}
    </RepeatBackgroundStyle>
  );
};

export default RepeatBackground;
