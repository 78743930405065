import React from "react";

import Styled from "styled-components";

import BoidsIMG from "assets/boids_flock_RGB.png";

import Strings from "constants/strings.json";

const BoidsContainer = Styled.div`
    position: fixed;
    right: 10px;
    bottom: 10px;
`;

const Boids = () => (
  <BoidsContainer>
    <img
      src={BoidsIMG}
      width={window.innerWidth * 0.2}
      alt={Strings.boidsImageAlt}
    />
  </BoidsContainer>
);

export default Boids;
