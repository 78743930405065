import React from "react";
import Styled from "styled-components";

const PlacementContainerStyle = Styled.div`
    position: relative;
    height: ${({ height }) => height};
    width: ${({ width }) => (width ? width : "auto")};
    display: flex;
    align-items: flex-end; 
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : "transparent"};
`;

const PlacementContainer = ({ backgroundColor, width, children, height }) => {
  return (
    <PlacementContainerStyle
      backgroundColor={backgroundColor}
      width={width}
      height={height}
    >
      {children}
    </PlacementContainerStyle>
  );
};

export default PlacementContainer;
