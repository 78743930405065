import React, { useEffect } from "react";

import Helmet from "react-helmet";

import Strings from "constants/strings.json";
import Colors from "constants/colors";
import DayPicker, { DateUtils } from "react-day-picker";
import { parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";

const DatePicker = ({ setDateState, dateState }) => {
  useEffect(() => {
    setDateState(getInitialState());
    // eslint-disable-next-line
  }, []);

  function getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
      isEndDateValid: true,
      isWeekend: false,
      valid: false
    };
  }

  function isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  function isEndDateValidFunc(day) {
    var today = new Date();
    var endOfDayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    );
    const validEndDate = day >= endOfDayDate;
    setDateState({
      ...dateState,
      isEndDateValid: validEndDate,
      isWeekend: false
    });
    return validEndDate;
  }

  function handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      setDateState({
        ...dateState,
        isEndDateValid: true,
        isWeekend: true
      });
      return;
    }

    const { from, to } = dateState;
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      var dayAtStartTime = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        0,
        0,
        0
      );
      setDateState({
        ...dateState,
        from: dayAtStartTime,
        to: null,
        enteredTo: null,
        valid: false,
        isWeekend: false
      });
    } else if (isEndDateValidFunc(day)) {
      var dayAtEndTime = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        23,
        59,
        59
      );
      setDateState({
        ...dateState,
        to: dayAtEndTime,
        enteredTo: dayAtEndTime,
        isEndDateValid: true,
        valid: true,
        isWeekend: false
      });
    }
  }

  function handleDayMouseEnter(day) {
    const { from, to } = dateState;
    if (!isSelectingFirstDay(from, to, day)) {
      setDateState({
        ...dateState,
        enteredTo: day
      });
    }
  }

  function handleResetClick() {
    setDateState(getInitialState());
  }

  const { from, to, enteredTo, isEndDateValid, isWeekend, valid } = dateState;
  const modifiers = { start: from, end: enteredTo };
  const selectedDays = [from, { from, to: enteredTo }];

  return (
    <div>
      <div>
        {!from && !to && Strings.selectStartDate}
        {from && !to && Strings.selectEndDate}
        {from &&
          to &&
          `Start: ${from.toLocaleDateString()} - End:
              ${to.toLocaleDateString()}`}{" "}
      </div>
      <DayPicker
        className="Range"
        numberOfMonths={2}
        fromMonth={from}
        parseDate={parseDate}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        firstDayOfWeek={1}
        disabledDays={{ daysOfWeek: [0, 6] }}
      />

      <p style={{ color: Colors.error }}>
        {!isEndDateValid && Strings.endDayWarning}
        {isWeekend && !valid && Strings.weekendWarning}
        &nbsp; {/* An empty space to prevent text from jumping */}
      </p>
      <Helmet>
        <style>{`
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${Colors.secondary} !important;
    color: ${Colors.primary};
  }
  .Range .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Range .DayPicker-Day--start {
    background-color: ${Colors.primary} !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Range .DayPicker-Day--end {
    background-color: ${Colors.primary} !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
      </Helmet>
    </div>
  );
};

export default DatePicker;
