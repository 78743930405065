const rawColors = {
  navy: "#2C3F6B",
  palePurple: "#A6A2DC",
  purple: "#7473BD",
  darkPurple: "#565C98",
  whiteGrey: "#FAFAFA",
  lightGrey: "#EFEFEF",
  paleCoral: "#FFACAC",
  coral: "#FF7979",
  neonPurple: "#9D8DFF",
  darkNeonPurple: "#4A49CB",
  white: "#FFFFFF",
  sunsetOrange: "#ff4d4d",
  glassGrey: "#d0d1e6"
};

const colorScheme = {
  primary: rawColors.purple,
  secondary: rawColors.palePurple,
  highlight: rawColors.coral,
  error: rawColors.sunsetOrange,
  text: rawColors.navy,
  placeholderText: rawColors.lightGrey
};

const goalColors = {
  Contacts: '#4bc213', // Green
  Meetings: '#c27013', // Orange
  Hires: '#1696c4' // Blue
};

export default {
  ...rawColors,
  ...colorScheme,
  goalColors
};
