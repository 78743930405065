import React, { useEffect } from "react";
import Wizard from "components/createGame/Wizard";
import GameView from "components/gameView/GameView";
import GameStatisticsView from "components/gameStatisticsView/GameStatisticsView";
import Logo from "components/logo";
import Boids from "components/Boids";
import styled from "styled-components";
import Colors from "./constants/colors";
import NavBar from "components/NavBar";
import NavContainer from "components/NavContainer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  MsalProvider,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "authConfig";

const AppStyle = styled.div`
  text-align: center;
`;

const AppHeader = styled.div`
  background-color: ${Colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: ${Colors.text};
`;

const LoginButtonContainer = styled.div`
display: flex;
flex-direction: column;
width: 100vw;
height: 100vh;
align-items: center;
justify-content: center;
`

const LoginButton = styled.button`
width: 70px;
height: 40px;
border-radius: 8px;
border: none;
color: white;
font-size: 0.9375rem;
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
font-weight: bolder
background-color: rgb(101, 89, 255);
-webkit-transition-duration: 0.4s; /* Safari */
transition-duration: 0.4s;
&:hover {
  cursor: pointer
  background-color: rgb(74, 73, 203);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
`

const createGame = ({ match, location, history }) => {
  return (
    <>
      <Wizard history={history}></Wizard>
      <Boids />
    </>
  );
};

const viewGame = ({ match }) => {
  return <GameView gameUUID={match.params.gameuuid}></GameView>;
};

const AppRouter = () => {
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent
  );

  const { accounts } = useMsal();

  if (accounts.length >= 1) {
    return (
      <Router>
        <AppStyle>
          {/* Removed until bug gets fixed
        <NavContainer>
          <Logo />
          <NavBar/>
        </NavContainer>
        */}
          <AppHeader>
            <Switch>
              <Route path="/" exact component={createGame} />
              <Route path="/game/:gameuuid" component={viewGame} />
              <Route
                path="/gamehistory/:gameuuid"
                component={GameStatisticsView}
              />
            </Switch>
          </AppHeader>
        </AppStyle>
      </Router>
    );
  } else {
    return (
      <LoginButtonContainer>
        <LoginButton onClick={() => login(InteractionType.Popup, loginRequest)}>
          Login{" "}
        </LoginButton>
      </LoginButtonContainer>
    );
  }
};

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <AppRouter></AppRouter>
    </MsalProvider>
  );
}

export default App;
