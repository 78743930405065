import React from "react";
import Styled from "styled-components";
import Colors from "constants/colors";
import Strings from "constants/strings.json";

import UndoIcon from "assets/undo icon/undo_icon_white.svg";
import CloseIcon from "assets/close_button_white.svg";

const ButtonStyle = Styled.button`
    width: 120px;
    background-color: transparent;
    border-color: ${({ disabled }) =>
      disabled ? Colors.lightGrey : Colors.navy};
    color: ${({ disabled }) => (disabled ? Colors.lightGrey : Colors.navy)};
    cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
    font-size: 18px;
    height: 50px;
    border-radius: 100px;
    border: 2px solid;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px;
`;

const UndoButtonStyle = Styled.div`
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    background-color: transparent;
    border-color: transparent;
`;

const CloseButtonStyle = Styled.div`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    padding: 10px;
    background-color: transparent;
    border-color: transparent;
`;

const Button = ({ click, disabled, text }) => (
  <>
    <ButtonStyle onClick={click} disabled={disabled}>
      {text}{" "}
    </ButtonStyle>
  </>
);

const UndoButton = ({ onClick }) => (
  <UndoButtonStyle onClick={onClick}>
    <img src={UndoIcon} width="32px" height="32px" alt={Strings.undoImageAlt} />
  </UndoButtonStyle>
);

const CloseButton = ({ onClick }) => (
  <CloseButtonStyle onClick={onClick}>
    <img
      src={CloseIcon}
      width="32px"
      height="32px"
      alt={Strings.closeImageAlt}
    />
  </CloseButtonStyle>
);

export default Button;
export { UndoButton, CloseButton };
