import React, { useState, useEffect, useRef } from "react";
import Styled from "styled-components";
import { updateGameUserGoals } from "utils/game.utils";
import { getCookie, setCookie } from "utils/cookie.utils";

import Colors from "constants/colors";
import UserStyles from "constants/userStyles";

import AlertOverlay from "components/Alert";
import Loader from "components/Loader";
import ProgressBar from "./ProgressBar";

import PlacementContainer from "components/layouts/PlacementContainer";
import PlacementElement from "components/layouts/PlacementElement";
import StickyTop from "components/layouts/StickyTop";
import RepeatBackground from "components/layouts/RepeatBackground";
import Icon from "components/layouts/Icon";
import SelectUser from "components/gameView/SelectUser";

import Grass from "assets/grass.svg";
import Start from "assets/StartAndEnd/scary_place.png";
import End from "assets/StartAndEnd/castle.png";
import RaceIcon from "components/gameView/Race";

import { getConnection, updateGoalWS } from "services/WebSocket";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const GameViewStyle = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h1 {
    margin-block-start: 1em;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  p {
    margin-block-end: 0em;
    overflow-wrap: break-word;
    word-break: break-all;
  }
`;

const getUserProgress = user => {
  var progress = 0;
  var target = 0;
  user.Goals.forEach(goal => {
    progress += goal.Current;
    target += goal.Target;
  });
  user.Progress = (progress / target) * 100;
  return user;
};

const getUserColors = gameObj => {
  let user_IDs = gameObj.User.map(({ ID }) => ID).sort();
  for (let i = 0; i < user_IDs.length; i++) {
    for (let j = 0; j < gameObj.User.length; j++) {
      if (gameObj.User[j].ID === user_IDs[i]) {
        gameObj.User[i].Colors = UserStyles[i % UserStyles.length].colors;
        gameObj.User[i].Monster = UserStyles[i % UserStyles.length].monster;
        gameObj.User[i].Runner = UserStyles[i % UserStyles.length].runner;
      }
    }
  }
};

const setSelectedUserFirst = (gameObj, selectedUser) => {
  for (let i = 0; i < gameObj.User.length; i++) {
    if (gameObj.User[i].ID === parseInt(selectedUser, 10)) {
      let tmp = gameObj.User.splice(i, 1)[0];
      gameObj.User.unshift(tmp);
    }
  }
};

const findMonster = user =>
  user
    .slice(1, user.length)
    .every(u => Math.abs(u.Progress - user[0].Progress) < 5)
    ? {
      ID: -1,
      Same: user
        .slice(1, user.length)
        .every(u => u.Progress === user[0].Progress)
    } // If the same then no monster
    : user.reduce(
      (monster, user) => (user.Progress < monster.Progress ? user : monster),
      { ID: -1, Progress: 101 }
    );

const GameDetails = ({ gameTitle, gameDescription, startDate, endDate }) => {
  return (
    <>
      <h1>{gameTitle}</h1>
      <p>{gameDescription}</p>
      <p>
        {startDate} - {endDate}
      </p>
    </>
  );
};

const GameView = ({ gameUUID }) => {
  const { result } = useMsalAuthentication(InteractionType.Silent);

  const [game, setGame] = useState(null);
  const [reward, setReward] = useState({});
  const [showReward, setShowReward] = useState(false);
  const [monster, setMonster] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // Needed for the observable to see new changes
  // It has to do with how useEffect works
  // Check this post:
  // https://stackoverflow.com/questions/55335898/cant-see-state-inside-method-helper-function-using-hooks
  const gameRef = useRef(game);
  useEffect(() => {
    gameRef.current = game;
  }, [game]);

  const selectedUserRef = useRef(selectedUser);
  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  }

  useEffect(() => {
    if (result?.idToken) {
      !game && fetchGame();
    }
    // eslint-disable-next-line
  }, [result]);

  const onNextGame = gameObj => {
    // Set user progress
    gameObj.User = gameObj.User.map(getUserProgress);

    // Set user colors
    getUserColors(gameObj);

    var selectedUser = getCookie(gameObj.UUID);
    if (selectedUser) {
      console.log("Selected user: " + selectedUser);
      setSelectedUser(parseInt(selectedUser));
    }

    // Set Selected User First
    setSelectedUserFirst(gameObj, selectedUser);

    // Find whos the monster
    setMonster(findMonster(gameObj.User));

    setGame(gameObj);
  };

  const onNextGoalUpdate = updatedGoalResponse => {
    const userID = updatedGoalResponse.Goals[0].UserID;

    const currentProgress = updatedGoalResponse.Goals.reduce(
      (prev, next) => prev + next.Current,
      0
    ); //sum of all the goals target value for the user
    const giveRewardAt = updatedGoalResponse.RewardGiveAt;

    // Only show reward if its for the selected user
    if (userID === selectedUserRef.current) {
      setReward(updatedGoalResponse.Reward);

      // Set show reward
      currentProgress === giveRewardAt
        ? setShowReward(true)
        : setShowReward(false);
    }

    // Create a clone of the game object
    const gameClone = { ...gameRef.current };

    // Map User and goal Array to new arrays to update game with
    const updatedGameClone = updateGameUserGoals(
      gameClone,
      userID,
      updatedGoalResponse.Goals,
      giveRewardAt,
      updatedGoalResponse.RewardType
    );

    // Update user progress
    updatedGameClone.User = updatedGameClone.User.map(getUserProgress);

    // Find whos the monster
    setMonster(findMonster(updatedGameClone.User));

    // Set new game state
    console.log("Goals updated");
    setGame(updatedGameClone);
  };

  const runAction = msg => {
    switch (msg.MessageType) {
      case 1:
        onNextGame(msg.Payload.Game);
        break;
      case 2:
        onNextGoalUpdate(msg.Payload);
        break;
      case 3:
        // pong received
        break;
      default:
        console.log("No match for message type: " + msg.MessageType);
    }
  };

  const fetchGame = () => {
    const observable = getConnection(gameUUID, result?.idToken);

    observable.subscribe(msgJSON => {
      let msg = JSON.parse(msgJSON);
      console.log(msg);
      runAction(msg);
    });
  };

  const handleGoalUpdate = (goal, progress) => {
    console.log("Updating goal:" + goal.ID);
    updateGoalWS(game.UUID, goal.UserID, goal.ID, progress);
  };

  const handleOverlayData = setData => {
    const data = {
      Title: reward.Title,
      Text: reward.Description
    };
    setData(data);
  };

  const setUserCookie = userID => {
    var start = new Date(game.StartDate);
    var end = new Date(game.EndDate);
    var daysTilEnd = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);

    // Set the cookie to expire 1 week after the game ends
    setCookie(game.UUID, userID, daysTilEnd + 7);
    setSelectedUser(userID);
    let game_clone = { ...game };
    setSelectedUserFirst(game_clone, userID);
    setGame(game_clone);
  };

  const contentWidth = window.innerWidth * 0.8;
  const iconWidth = window.innerWidth * 0.08;

  return (
    <GameViewStyle>
      {game === null ? (
        <Loader></Loader>
      ) : (
          <>
            <SelectUser
              visible={selectedUser === null}
              users={game.User}
              setSelectedUser={setUserCookie}
            />
            <AlertOverlay
              getData={handleOverlayData}
              visible={showReward}
              setVisible={setShowReward}
            ></AlertOverlay>
            <GameDetails
              gameTitle={game.Title}
              gameDescription={game.Description}
              startDate={formatDate(game.StartDate)}
              endDate={formatDate(game.EndDate)}
            ></GameDetails>

            <StickyTop width={contentWidth + "px"}>
              <PlacementContainer backgroundColor={Colors.white} height="100px">
                {game.User.map((user, i) => {
                  console.log(
                    `User: ${user.Name} = ${(user.Progress / 100) * contentWidth +
                    "px"}`
                  );
                  return (
                    <PlacementElement
                      key={i}
                      placement={
                        (user.Progress / 100) * (window.innerWidth * 0.8) +
                        (monster.ID === -1 && monster.Same && user.ID % 2 === 0
                          ? 5
                          : 0) +
                        "px"
                      }
                    >
                      {user.ID === monster.ID ? (
                        <RaceIcon
                          image={user.Monster}
                          width={iconWidth + "px"}
                          z_index="0"
                          y_transformation="15"
                        ></RaceIcon>
                      ) : (
                          <RaceIcon
                            image={user.Runner}
                            width={iconWidth + "px"}
                            z_index="1"
                            y_transformation="0"
                          ></RaceIcon>
                        )}
                    </PlacementElement>
                  );
                })}

                <PlacementElement
                  placement={-iconWidth + "px"}
                  alignSelf="flex-end"
                >
                  <Icon iconUrl={Start} width={iconWidth + "px"} />
                </PlacementElement>

                <PlacementElement
                  placement={contentWidth + "px"}
                  alignSelf="flex-end"
                >
                  <Icon iconUrl={End} width={iconWidth * 0.8 + "px"} />
                </PlacementElement>

                <RepeatBackground backgroundUrl={Grass} />
              </PlacementContainer>
            </StickyTop>

            {game.User.map((user, i) => {
              return (
                <ProgressBar
                  key={i}
                  goalUpdate={handleGoalUpdate}
                  user={user}
                  startDate={game.StartDate}
                  endDate={game.EndDate}
                  primaryColor={user.Colors.primary}
                  secondaryColor={user.Colors.secondary}
                  clickable={
                    user.ID === parseInt(selectedUser, 10) ? true : false
                  }
                />
              );
            })}
          </>
        )}
    </GameViewStyle>
  );
};

export default GameView;
