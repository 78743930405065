import React, { useState } from "react";
import Styled from "styled-components";
import HContainer from "components/layouts/HContainer";
import VContainer from "components/layouts/VContainer";
import Colors from "constants/colors";
import Strings from "constants/strings.json";

const TextBoxStyle = Styled.div`
  width: 100%;

  & input[type="text"] {
    border: 0;
    border-bottom: 1px solid
      ${({ valid }) => (valid ? Colors.palePurple : Colors.error)};
    outline: none;
    background-color: transparent;
    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "100%")};
    transition: all 125ms ease-out 125ms;
    text-align: left;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  }

  & input:focus {
    border-bottom: 1px dotted
      ${({ valid }) => (valid ? Colors.palePurple : Colors.error)};
  }
`;

const ErrorStyle = Styled.p`
  margin: 0;
  font-size: small;
  color: Colors.error;
`;

const TextBox = ({ onChange, valid, value, defaultText, maxLength, error }) => {
  const [isTypedIn, setIsTypedIn] = useState(false);
  const handleChange = event => {
    setIsTypedIn(true);
    const text = event.target.value;

    if (onChange) onChange(text);
  };

  return (
    <TextBoxStyle valid={valid || !isTypedIn}>
      <input
        type="text"
        value={value}
        placeholder={defaultText}
        onChange={handleChange}
        maxLength={maxLength ? maxLength : 100}
      />
      {!valid && isTypedIn && (
        <ErrorStyle>{error ? error : Strings.textBoxDefaultError}</ErrorStyle>
      )}
    </TextBoxStyle>
  );
};

const TextBoxWithLabel = ({
  label,
  value,
  defaultText,
  onChange,
  valid,
  error,
  position
}) => {
  const child = (
    <>
      <label>{label}</label>
      <TextBox
        value={value}
        defaultText={defaultText}
        onChange={onChange}
        valid={valid}
        error={error ? error : Strings.textBoxWithLabelDefaultError}
      />
    </>
  );

  switch (position) {
    case "above":
      return <VContainer textAlign="left">{child}</VContainer>;

    default:
      return <HContainer justifyContent="">{child}</HContainer>;
  }
};

export { TextBox, TextBoxWithLabel };
