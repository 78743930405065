import React, {useEffect,useState} from 'react';
import Styled, {keyframes} from 'styled-components';
import {fadeIn, fadeOut} from 'react-animations';


const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const FadeStyle = Styled.div`
    animation: ${({show}) => show ? fadeInAnimation : fadeOutAnimation} ${({show}) => show ? '1s' : '0.4s'};
`;

const Fade = ({show, children}) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show)
            setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if(!show)
            setRender(false);
    }

    return (
        render && (
            <FadeStyle show={show} onAnimationEnd={onAnimationEnd} >
                {children}
            </FadeStyle>
        )
    );

}

export default Fade;