import React from 'react';
import Styled from 'styled-components';

const VContainerStyle = Styled.div`
    display: flex;
    flex-direction: column;
    width: ${({width}) => width ? width : "auto"};
    height: ${({height}) => height ? height : "auto"};
    text-align: ${({textAlign}) => textAlign ? textAlign : "center"};
    margin: 10px;

    h4 {
        overflow-wrap: break-word;
        word-break: break-all;
      }
`;

/*
 *  Horizontal container
*/

const VContainer = ({textAlign, width, children}) => 
    <VContainerStyle textAlign={textAlign} width={width}>
        {children}
    </VContainerStyle>

export default VContainer;