import React, { useState, useEffect } from "react";
import HContainer from "components/layouts/HContainer";
import VContainer from "components/layouts/VContainer";
import Loader from "components/Loader";
import Boids from "components/Boids";
import { getGameHistoryByID } from "services/gameService";

import colors from "constants/colors";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const GameStatisticsView = ({ match }) => {
  const { result } = useMsalAuthentication(InteractionType.Silent);

  const [loaded, setLoaded] = useState(false);
  const [gameHistory, setGameHistory] = useState(null);
  const [goalHistory, setGoalHistory] = useState([]);
  const [goalTypes, setGoalTypes] = useState(null);
  const [goalColors] = useState(colors.goalColors);
  const gameUUID = match.params.gameuuid;

  useEffect(() => {
    if (!loaded) {
      getGameHistoryByID(gameUUID, result.idToken).then((json) => {
        setGameHistory(json);
        setLoaded(true);
      });
    }
  }, [loaded]);

  useEffect(() => {
    if (gameHistory === null) return;

    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const uniqueGoalNames = gameHistory
      .flatMap((g) => g.Goals.map((goal) => goal.Name))
      .filter(unique);

    setGoalTypes(uniqueGoalNames);

    const getAccumulatedGoalList = (goals) => {
      let acc = 0;
      let perDayAcc = {};

      goals.forEach((goal) => {
        goal.GoalHistory.forEach((gh) => {
          const dateString = new Date(gh.CreatedAt).toLocaleDateString();
          if (!(dateString in perDayAcc)) perDayAcc[dateString] = {};

          if (!(goal.Name in perDayAcc[dateString]))
            perDayAcc[dateString][goal.Name] = acc;

          acc += gh.ProgressIndex;
          perDayAcc[dateString][goal.Name] += gh.ProgressIndex;
        });
        acc = 0;
      });

      // Make sure all goals have a data point for each date.
      const dates = Object.keys(perDayAcc);
      for (var i = 0; i < dates.length; i++) {
        uniqueGoalNames.forEach((goalName) => {
          if (!(goalName in perDayAcc[dates[i]])) {
            // If no datapoint on first date, set to 0
            if (i === 0) perDayAcc[dates[i]][goalName] = 0;
            // Else take last
            else {
              perDayAcc[dates[i]][goalName] = perDayAcc[dates[i - 1]][goalName];
            }
          }
        });
      }

      const goalsAccumulated = Object.keys(perDayAcc).map((d) => {
        return {
          date: d,
          ...perDayAcc[d],
        };
      });

      return goalsAccumulated;
    };

    const accumulatedGoalHist = gameHistory.map((userGoals) => {
      return {
        UserID: userGoals.UserID,
        UserName: userGoals.UserName,
        Goals: getAccumulatedGoalList(userGoals.Goals),
      };
    });

    setGoalHistory(accumulatedGoalHist);
  }, [gameHistory]);

  return (
    <VContainer width="80vw">
      {loaded ? (
        <>
          <h1>Game Statistics</h1>
          <Boids />
          {goalHistory.map((gh) =>
            gh.Goals.length > 0 ? (
              <>
                <p>Team {gh.UserName} goal overview</p>
                <HContainer>
                  <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
                    <LineChart data={gh.Goals}>
                      <XAxis dataKey="date" tick={false} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {goalTypes.map((goalName) => (
                        <Line
                          type="monotone"
                          dataKey={goalName}
                          stroke={goalColors[goalName]}
                          activeDot={{ r: 8 }}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </HContainer>
              </>
            ) : (
              <p>No history registered for Team {gh.UserName} yet</p>
            )
          )}
        </>
      ) : (
        <Loader />
      )}
    </VContainer>
  );
};

export default GameStatisticsView;
