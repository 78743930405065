import React from "react";
import logo from "assets/NL logo/nl_logo_rgb.svg";
import Strings from "constants/strings.json";

import Styled from "styled-components";

const LogoStyle = Styled.nav`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    padding-left: 20px;
    align-self: flex-start;
`;

const Logo = () => {
  return (
    <LogoStyle>
      <img
        src={logo}
        alt={Strings.logoImageAlt}
        height={window.innerHeight * 0.06}
      />
    </LogoStyle>
  );
};

export default Logo;
