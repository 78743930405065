import React from "react";
import Styled from "styled-components";

const PlacementStyle = Styled.div`
    position: absolute;
    left: ${({placement}) => placement};
    align-self: ${({alignSelf}) => alignSelf ? alignSelf : 'center'};
`

const PlacementElement = ({placement, alignSelf, children}) => {
    return <PlacementStyle placement={placement} alignSelf={alignSelf} >
        {children}
    </PlacementStyle>
}

export default PlacementElement;