import React, { useEffect, useState } from "react";
import Styled, { keyframes } from "styled-components";
import Colors from "constants/colors";
import Strings from "constants/strings.json";
import { CloseButton } from "./Button.js";
import { slideOutUp } from "react-animations";
import ConfettiImg from "assets/confetti.png";

const slideOutUpAnimation = keyframes`${slideOutUp}`;

const AlertOverlayStyle = Styled.button`
    background-color: ${Colors.glassGrey};
    position: fixed;
    border-radius: 10px;
    padding-left: 200px;
    padding-right: 200px;
    color: ${Colors.white};
    opacity: 0.95;
    margin: auto;
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
    animation: 1s ${slideOutUpAnimation};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index:1000;
`;

const TitleStyle = Styled.div`
    font-weight: 600;
    font-size: 50px;
    text-transform: uppercase;
    margin-bottom: 100px;
    margin-top: 20px;
`;

const TextStyle = Styled.div`
    font-size: 30px;
    min-width: 500px;
    max-width: 600px;
    width: auto;
    margin-bottom: 100px;
    margin-top: 50px;
    font-weight: 600;

`;

const AlertOverlay = ({ setVisible, getData, visible }) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState(null);

  const handleCloseClick = e => {
    e.stopPropagation();
    setVisible(false);
    setData(null);
    setRender(false);
  };

  const fetchData = () => {
    getData(setData);
  };

  useEffect(() => {
    visible && !data && fetchData();

    if (visible) {
      setRender(visible);
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    render &&
    data && (
      <AlertOverlayStyle visible={render}>
        <CloseButton onClick={handleCloseClick}></CloseButton>
        <TitleStyle>{data.Title}</TitleStyle>
        <img src={ConfettiImg} width="256px" alt={Strings.alertImageAlt}></img>
        <TextStyle>{data.Text}</TextStyle>
      </AlertOverlayStyle>
    )
  );
};

export default AlertOverlay;
