import React from 'react';
import Styled from 'styled-components';

const HContainerStyle = Styled.div`
    display: flex;
    flex-direction: row;
    width: ${({width}) => width ? width : "auto"};
    height: ${({height}) => height ? height : "auto"};
    justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'space-between'};
    align-items: ${({alignItems}) => alignItems ? alignItems : 'baseline'};
    margin: 10px;
`;

/*
 *  Horizontal container
*/

const HContainer = ({height, width, justifyContent, alignItems, children}) => 
    <HContainerStyle height={height} width={width} justifyContent={justifyContent} alignItems={alignItems}>
        {children}
    </HContainerStyle>

export default HContainer;