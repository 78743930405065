import React from "react";
import Styled from "styled-components";

import Colors from "constants/colors";
import { UndoButton } from "components/Button";

const ProgressBoxStyle = Styled.div`
    background-image: linear-gradient(180deg, ${({ backgroundColor }) =>
      backgroundColor} ${({ progress }) => 100 - progress}%, ${({
  fillColor
}) => fillColor} ${({ progress }) => 100 - progress}%);
        color: ${Colors.white};
    
    font-weight: bold;
    flex-grow: 1;
    margin: 10px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: ${({ cursor }) => cursor};

    border-radius: 5px;

    user-select: none;

    position: relative;
`;

const ProgressBox = ({
  goalDecUpdate,
  goalIncUpdate,
  progress,
  name,
  Current,
  Target,
  fillColor,
  backgroundColor,
  clickable
}) => {
  const handleUndoClick = e => {
    e.stopPropagation();
    console.log("Undo");
    goalDecUpdate();
  };

  return (
    <ProgressBoxStyle
      onClick={clickable ? goalIncUpdate : {}}
      progress={progress}
      backgroundColor={backgroundColor}
      fillColor={fillColor}
      cursor={clickable ? "pointer" : ""}
    >
      <div>{name} </div>
      <div>
        {Current} of {Target}
      </div>
      {clickable && <UndoButton onClick={handleUndoClick} />}
    </ProgressBoxStyle>
  );
};

export default ProgressBox;
