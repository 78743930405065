import Colors from "constants/colors";

import PurpleMonsterImage from "assets/Players/monster_purple.gif";
import CoralMonsterImage from "assets/Players/monster_coral.gif";
import PurpleRunnerImage from "assets/Players/runner_purple.gif";
import CoralRunnerImage from "assets/Players/runner_coral.gif";

// Set colors and gifs used for each team
const UserStyles = [
  {
    colors: {
      primary: Colors.purple,
      secondary: Colors.palePurple
    },
    runner: PurpleRunnerImage,
    monster: PurpleMonsterImage
  },
  {
    colors: {
      primary: Colors.coral,
      secondary: Colors.paleCoral
    },
    runner: CoralRunnerImage,
    monster: CoralMonsterImage
  }
];

export default UserStyles;
