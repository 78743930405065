import React, { useEffect, useState } from "react";
import styled from "styled-components";

import StepWizard from "react-step-wizard";
import HContainer from "components/layouts/HContainer";
import VContainer from "components/layouts/VContainer";
import Button from "components/Button";
import { TextBoxWithLabel } from "components/TextBox";
import DatePicker from "components/DateRangePicker";

import { createGame, getActiveGames } from "services/gameService";
import { isPositiveNummeric, isEmpty } from "extensions/stringExtensions";

import Colors from "constants/colors";
import Strings from "constants/strings.json";

import Mensch from "assets/Mensch/beamensch.jpg";
import Select from "components/Select";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

/*
    Styled components
*/
const NumberContainerStyle = styled.div`
  border-radius: 15px;
  border: 1px solid ${Colors.purple};
  ${({ currentStep, value }) =>
    currentStep === value && "box-shadow: 1px 1px 1px 1px #888888"};
  background-color: ${Colors.whileGrey};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumberStyle = styled.div`
  border-radius: 100%;
  background-color: ${({ currentStep, value }) =>
    currentStep === value ? Colors.coral : Colors.palePurple};
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepOne = ({
  setGameTitle,
  gameTitle,
  setGameDescription,
  gameDescription,
  setUserOne,
  userOne,
  setUserTwo,
  userTwo,
  setDateState,
  dateState,
}) => {
  return (
    <VContainer>
      <TextBoxWithLabel
        label={Strings.labelGameTitle}
        defaultText={Strings.labelGameTitleDefaultText}
        onChange={setGameTitle}
        value={gameTitle}
        valid={!isEmpty(gameTitle)}
        position="above"
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelGameDescription}
        defaultText={Strings.labelGameDescriptionDefaultText}
        onChange={setGameDescription}
        value={gameDescription}
        valid={true}
        position="above"
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelUserOne}
        defaultText={Strings.labelUserOneDefaultText}
        onChange={setUserOne}
        value={userOne}
        valid={!isEmpty(userOne)}
        position="above"
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelUserTwo}
        defaultText={Strings.labelUserTwoDefaultText}
        onChange={setUserTwo}
        value={userTwo}
        valid={!isEmpty(userTwo)}
        position="above"
      ></TextBoxWithLabel>
      <DatePicker dateState={dateState} setDateState={setDateState} />
    </VContainer>
  );
};

const StepTwo = ({
  userOne,
  setUserOneContactGoal,
  userOneContacts,
  setUserOneMeetingGoal,
  userOneMeetings,
  setUserOneHireGoal,
  userOneHirings,
  userTwo,
  setUserTwoContactGoal,
  userTwoContacts,
  setUserTwoMeetingGoal,
  userTwoMeetings,
  setUserTwoHireGoal,
  userTwoHirings,
}) => {
  return (
    <VContainer textAlign="left">
      <h4>{userOne ? userOne : "No Name"}:</h4>
      <TextBoxWithLabel
        label={Strings.labelContacts}
        onChange={setUserOneContactGoal}
        valid={isPositiveNummeric(userOneContacts)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelMeetings}
        onChange={setUserOneMeetingGoal}
        valid={isPositiveNummeric(userOneMeetings)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelHirings}
        onChange={setUserOneHireGoal}
        valid={isPositiveNummeric(userOneHirings)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>

      <h4>{userTwo ? userTwo : "No Name"}:</h4>
      <TextBoxWithLabel
        label={Strings.labelContacts}
        onChange={setUserTwoContactGoal}
        valid={isPositiveNummeric(userTwoContacts)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelMeetings}
        onChange={setUserTwoMeetingGoal}
        valid={isPositiveNummeric(userTwoMeetings)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>
      <TextBoxWithLabel
        label={Strings.labelHirings}
        onChange={setUserTwoHireGoal}
        valid={isPositiveNummeric(userTwoHirings)}
        error={Strings.goalInputErrorMessage}
      ></TextBoxWithLabel>
    </VContainer>
  );
};

const StepThree = () => {
  return (
    <img
      src={Mensch}
      alt={Strings.menschImageAlt}
      height={window.innerHeight * 0.5}
    />
  );
};

/*
    Functional components
*/

const Step = ({
  currentStep,
  children,
  previousStep,
  nextStep,
  valid,
  submit,
}) => {
  return (
    <>
      <HContainer width={window.innerWidth * 0.5 + "px"}>
        <Number value={1} currentStep={currentStep} />
        <Number value={2} currentStep={currentStep} />
        <Number value={3} currentStep={currentStep} />
      </HContainer>

      {children}

      <HContainer
        alignItems="center"
        justifyContent={currentStep === 1 ? "flex-end" : null}
      >
        {currentStep !== 1 && (
          <Button text={Strings.backButtonText} click={previousStep} />
        )}
        {currentStep !== 3 && (
          <Button
            text={Strings.nextButtonText}
            click={nextStep}
            disabled={!valid}
          />
        )}
        {currentStep === 3 && (
          <Button text={Strings.startGameButtonText} click={submit} />
        )}
      </HContainer>
    </>
  );
};

const Number = ({ currentStep, value }) => (
  <NumberContainerStyle currentStep={currentStep} value={value}>
    <NumberStyle currentStep={currentStep} value={value}>
      <p>{value}</p>
    </NumberStyle>
  </NumberContainerStyle>
);

const Wizard = ({ defaultText, history }) => {
  const { result } = useMsalAuthentication(InteractionType.Silent);

  const [activeCookieGames, setActiveCookieGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState("");

  const [gameTitle, setGameTitle] = useState(defaultText);
  const [gameDescription, setGameDescription] = useState(defaultText);
  const [userOne, setUserOne] = useState(defaultText);
  const [userTwo, setUserTwo] = useState(defaultText);
  const [dateState, setDateState] = useState({ valid: false });

  const [userOneContactGoal, setUserOneContactGoal] = useState(-1);
  const [userOneMeetingGoal, setUserOneMeetingGoal] = useState(-1);
  const [userOneHireGoal, setUserOneHireGoal] = useState(-1);

  const [userTwoContactGoal, setUserTwoContactGoal] = useState(-1);
  const [userTwoMeetingGoal, setUserTwoMeetingGoal] = useState(-1);
  const [userTwoHireGoal, setUserTwoHireGoal] = useState(-1);

  // Check if step one is in a valid state
  const stepOneValid = gameTitle && userOne && userTwo && dateState.valid;

  // Check if step two is in a valid state
  const stepTwoProperties = [
    userOneContactGoal,
    userOneMeetingGoal,
    userOneHireGoal,
    userTwoContactGoal,
    userTwoMeetingGoal,
    userTwoHireGoal,
  ];
  const stepTwoReduce = (acc, val) => acc && isPositiveNummeric(val);
  const stepTwoValid = stepTwoProperties.reduce(stepTwoReduce, true);

  const gameSaved = (gameUUID) => {
    history.push("/game/" + gameUUID);
  };

  const handleSubmit = () => {
    const game = {
      title: gameTitle,
      description: gameDescription,
      startDate: dateState.from,
      endDate: dateState.to,
      user: [
        {
          name: userOne,
          goals: [
            { name: "Contacts", target: parseInt(userOneContactGoal) },
            { name: "Meetings", target: parseInt(userOneMeetingGoal) },
            { name: "Signings", target: parseInt(userOneHireGoal) },
          ],
        },
        {
          name: userTwo,
          goals: [
            { name: "Contacts", target: parseInt(userTwoContactGoal) },
            { name: "Meetings", target: parseInt(userTwoMeetingGoal) },
            { name: "Signings", target: parseInt(userTwoHireGoal) },
          ],
        },
      ],
    };

    createGame(game, gameSaved, result.idToken);
  };

  const navigateCachedGame = () => {
    if (selectedGame) {
      history.push("/game/" + selectedGame);
    }
  };

  useEffect(() => {
    if (document.cookie && result) {
      const cookies = document.cookie
        .split("; ")
        .map((cookie) => cookie.split("=")[0]);
      getActiveGames(cookies, (data) => setActiveCookieGames(data), result?.idToken);
    }
  }, [result]);

  return (
    <>
      <p>Create a new game or select an ongoing game</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          selected={selectedGame}
          onChange={(val) => setSelectedGame(val)}
          options={activeCookieGames.map((game) => ({
            title: game.Title,
            value: game.UUID,
          }))}
        />
        <Button
          disabled={!selectedGame}
          text={Strings.goToGame}
          click={() => navigateCachedGame()}
        />
      </div>

      <StepWizard initialStep={1}>
        <Step number="1" valid={stepOneValid}>
          <StepOne
            gameTitle={gameTitle}
            setGameTitle={setGameTitle}
            gameDescription={gameDescription}
            setGameDescription={setGameDescription}
            userOne={userOne}
            setUserOne={setUserOne}
            userTwo={userTwo}
            setUserTwo={setUserTwo}
            dateState={dateState}
            setDateState={setDateState}
          ></StepOne>
        </Step>
        <Step number="2" valid={stepTwoValid}>
          <StepTwo
            userOne={userOne}
            userOneContacts={userOneContactGoal}
            userOneMeetings={userOneMeetingGoal}
            userOneHirings={userOneHireGoal}
            setUserOneContactGoal={setUserOneContactGoal}
            setUserOneMeetingGoal={setUserOneMeetingGoal}
            setUserOneHireGoal={setUserOneHireGoal}
            userTwo={userTwo}
            userTwoContacts={userTwoContactGoal}
            userTwoMeetings={userTwoMeetingGoal}
            userTwoHirings={userTwoHireGoal}
            setUserTwoContactGoal={setUserTwoContactGoal}
            setUserTwoMeetingGoal={setUserTwoMeetingGoal}
            setUserTwoHireGoal={setUserTwoHireGoal}
          />
        </Step>
        <Step number="3" submit={handleSubmit}>
          <StepThree />
        </Step>
      </StepWizard>
    </>
  );
};

export default Wizard;
