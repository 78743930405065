import React from "react";
import Styled from "styled-components";

import Strings from "constants/strings.json";

const IconStyle = Styled.div`
    vertical-align: bottom;

    img {
        vertical-align: bottom;
    }
`;

const Icon = ({iconUrl, altText, width}) => {
    return <IconStyle>
                <img
                    src={iconUrl}
                    alt={altText ? altText : Strings.iconDefaultAltText}
                    width={width}
                />
           </IconStyle>
}

export default Icon;