import React from "react";
import Styled from "styled-components";
import Colors from "constants/colors"

const OverlayStyle = Styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    
    opacity: 0.95;
    filter: alpha(opacity=95);

    z-index: 100;
`;

const UserSelectionStyle = Styled.div`
    width: 50%;
    height: 100%;
    float: left;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-wrap: break-word;
    word-break: break-all;
    hyphens: auto;

    &:hover {
        background-color: ${props => props.color};
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
`;

const TextContainer = Styled.div`
    background-color: white;
`;

const SelectUser = ({visible, users, setSelectedUser}) => {
    return visible &&
     (<OverlayStyle>
        <TextContainer>
            <h1>Please select your team</h1>
            <p><small>Selecting a team accepts the usage of cookies</small></p>
        </TextContainer>

        {users.map((user,i) => <UserSelectionStyle 
            key={i} 
            onClick={() => setSelectedUser(user.ID)}
            color={i % 2 === 0 ? Colors.purple : Colors.coral } >
               <h1> {user.Name} </h1>
        </UserSelectionStyle>)}
    </OverlayStyle>);
}

export default SelectUser;