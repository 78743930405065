import React from "react";
import Styled from "styled-components";
import Colors from "constants/colors";

const Container = Styled.div`
    width: 400px;
    height: 100px;
    font-size: 18px;
    border-radius: 15px;
    border: 2px solid;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px
    display: flex;
    flex-direction: column;
    justify-content: ${({ options }) => (!options?.length ? "center" : "")};
    overflow: scroll;
`;

const Item = Styled.div`
  width: 100%;
  color: black;
  height: 100%;
  &:hover {
    background-color: ${Colors.lightGrey}
  }
  background-color: ${({ selected }) =>
    selected ? Colors.lightGrey : "transparent"}
`;

const Select = ({ options, onChange, selected }) => (
  <>
    <Container options={options}>
      {options.map((option) => (
        <Item
          selected={selected === option.value}
          key={option.value}
          onClick={() => onChange(option.value)}
        >
          <p>{option.title}</p>
        </Item>
      ))}
      {!options.length && <p>No ongoing games</p>}
    </Container>
  </>
);

export default Select;
