export const updateGameUserGoals = (gameClone, userID ,updatedGoals, rewardGiveAt, rewardType) => {
    const updatedGameUsers = gameClone.User.map(user => {
        if (user.ID === userID) {
            user.Goals = updatedGoals;
            user.RewardGiveAt = rewardGiveAt;
            user.RewardType = rewardType;
            return user;
        }
        return user;
    })

    gameClone.User = updatedGameUsers;

    return gameClone;
} 

export const getNumOfWorkHours = (startDate, endDate) => {
    var numWorkHours = 0;
    var currentDate = startDate;
    while (currentDate <= endDate) {
        // Skip Sunday and Saturday
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            if(currentDate.getDay() === endDate.getDay() 
            && currentDate.getMonth() === endDate.getMonth() 
            && currentDate.getFullYear() === endDate.getFullYear()){
                numWorkHours += endDate.getHours();

                if (endDate.getMinutes() >= 30){
                    numWorkHours++;
                }
            } else {
                numWorkHours += 24;
            }
            
        }
        currentDate = currentDate.addDays(1);
    }
    return numWorkHours;
}

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}