import Config from "constants/config";
import Strings from "constants/strings.json";

export const createGame = (game, onSuccess, token) => {
  console.log("Sending data");
  var gameData = JSON.stringify(game);
  console.log(gameData);

  var xhttp = new XMLHttpRequest();
  var url = Config.serverUrl + "/game";

  xhttp.open("POST", url, true);
  xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
  xhttp.setRequestHeader("Content-type", "application/json");
  xhttp.onload = (e) => {
    onSuccess(xhttp.responseText);
  };

  xhttp.onerror = (error) => {
    alert(Strings.gameCreateError);
    console.log(error);
  };

  xhttp.send(gameData);
};

export const getActiveGames = (gameUUIDS, onSuccess, token) => {
  var xhttp = new XMLHttpRequest();
  var url = Config.serverUrl + "/games/" + gameUUIDS.join(",");
  
  xhttp.open("GET", url, true);
  xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
  xhttp.onload = (e) => {
    onSuccess(JSON.parse(xhttp.responseText));
  };

  xhttp.onerror = (error) => {
    alert(Strings.gameCreateError);
    console.log(error);
  };

  xhttp.send();
};

export const getGame = (gameUUID, onSuccess, token) => {
  var xhttp = new XMLHttpRequest();
  
  var url = Config.serverUrl + "/game/" + gameUUID;
  
  xhttp.open("GET", url, true);
  xhttp.setRequestHeader("Authorization", `Bearer ${token}`);

  xhttp.onload = (e) => {
    onSuccess(xhttp.responseText);
  };

  xhttp.onerror = (error) => {
    alert(Strings.gameLoadError);
    console.log(error);
  };

  xhttp.send();
};

export const updateGoal = (
  gameUUID,
  userID,
  goalID,
  body,
  onSuccess,
  token
) => {
  var xhttp = new XMLHttpRequest();
  
  var url =
  Config.serverUrl + `/game/${gameUUID}/user/${userID}/goal/${goalID}`;
  
  xhttp.open("PUT", url, true);
  xhttp.setRequestHeader("Authorization", `Bearer ${token}`);
  xhttp.setRequestHeader("Content-type", "application/json");
  xhttp.onload = (e) => {
    onSuccess(xhttp.responseText);
  };

  xhttp.onerror = (error) => {
    alert(Strings.goalUpdateError);
    console.log(error);
  };

  var jsonBody = JSON.stringify(body);
  xhttp.send(jsonBody);
};

export const getRewardFromType = (rewardType, gameUUID, onSuccess, token) => {
  var xhttp = new XMLHttpRequest();

  var url = Config.serverUrl + `/reward/${rewardType}/game/${gameUUID}`;

  xhttp.open("GET", url, true);
  xhttp.setRequestHeader("Authorization", `Bearer ${token}`);

  xhttp.onload = (e) => {
    onSuccess(xhttp.responseText);
  };

  xhttp.onerror = (error) => {
    alert(Strings.rewardNotRetrievedError);
    console.log(error);
  };

  xhttp.send();
};

export const getGameHistoryByID = (gameUUID, token) => {
  return fetch(`${Config.serverUrl}/game/history/${gameUUID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
